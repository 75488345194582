<template>
  <div></div>
  <!-- <div class="border-top border-primary bg-light">
    <b-container class="h-100">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col md="8" lg="6" xl="5">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <h1 class="h2 mb-5 font-weight-bold text-uppercase text-center d-flex flex-column align-items-center justify-content-center">
              <LogoComponent :fill="'#212529'" :height="'40px'" :width="'40px'" class="mb-3" />USt.-ID Checker
            </h1>
            <b-alert variant="danger" :show="showAlert">{{errorMessage}}</b-alert>
            <b-form v-on:submit.prevent="signIn">
              <b-form-group>
                <label>Deine E-Mail-Adresse</label>
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <fa-icon :icon="['fas', 'user']" full-width />
                    </b-input-group-text>
                  </template>
                  <b-form-input type="email" v-model="email" />
                </b-input-group>
              </b-form-group>
              <b-form-group class="mb-4">
                <label>Dein Passwort</label>
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <fa-icon :icon="['fas', 'key']"  full-width />
                    </b-input-group-text>
                  </template>
                  <b-form-input type="password" v-model="password" />
                </b-input-group>
              </b-form-group>
              <b-form-group class="mb-0">
                <b-button variant="primary" type="submit" block size="lg" :disabled="email === '' || password === ''">
                  <fa-icon :icon="['fas', 'sign-in']" class="mr-2" />Anmelden
                </b-button>
              </b-form-group>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div> -->
</template>

<script>
import { signInWithEmailAndPassword, signInWithCustomToken, getAuth } from "firebase/auth";
// import LogoComponent from '../components/LogoComponent.vue';

export default {
  name: 'SignInView',
  /* components: {
    LogoComponent
  }, */
  data() {
    return {
      email: '',
      password: '',
      errorMessage: ''
    }
  },
  computed: {
    showAlert: function() {
      return this.errorMessage !== '' ? true : false
    }
  },
  methods: {
    signIn: function() {
      const emailRegExp = RegExp('^[a-z.-]+@comline-shop(.[a-z]+){1,2}$')

      if (emailRegExp.test(this.email) === false) {
        this.errorMessage = 'Die E-Mail-Adresse ist ungültig.'
        return
      }
      signInWithEmailAndPassword(getAuth(), this.email, this.password)
      .then(() => this.$router.replace({ name: 'CheckerView' }))
      .catch(error => {
        if (error.code === 'auth/wrong-password') this.errorMessage = 'Das Passwort ist ungültig.'
        else if (error.code === 'auth/user-not-found') this.errorMessage = 'Es existiert kein Benutzerdatensatz, der dieser Kennung entspricht.'
        else if (error.code === 'auth/user-disabled') this.errorMessage = 'Das Benutzerkonto wurde von einem Administrator deaktiviert.'
        else this.errorMessage = error.message
      })
    }
  },
  mounted(){
    let token = this.$route.params.token || 0

    signInWithCustomToken(getAuth(), token)
    .then(()=>{
      this.$router.push({name: 'CheckerView'})
    })
    .catch(err=>{console.error(err)})
  }
}
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
